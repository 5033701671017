<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="关联商品列表"
        sub-title=""
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
          <a-statistic title="" :value="'订单号：' + this.order_no" />
        </a-row>
      </a-page-header>

      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="count_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 100px">
          {{ text }}
        </div>
      </s-table>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '@/views/c-wms/commodity/modules/CreateForm'
import { pick_list_commodities } from '@/api/c_wms_picks'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      order_no: '',
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品名称',
          dataIndex: 'name'
        },
        {
          title: '供应商',
          dataIndex: 'goods_purchase.corporation'
        },
        {
          title: '批次',
          dataIndex: 'goods_purchase.batch'
        },
        {
          title: '库位',
          dataIndex: 'cargo_space.name'
        },
        {
          title: '数量',
          dataIndex: 'count',
          scopedSlots: { customRender: 'count_render' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return pick_list_commodities(this.$route.params.id, Object.assign(this.queryParam, parameter))
          .then(({ data }) => {
            return { entries: data }
          })
      }
    }
  },
  created () {
   this.order_no = this.$route.query.order_no
    console.log(this.order_no)
  },
  methods: {
  }
}
</script>
